import i18n from 'i18next';
import { reactI18nextModule } from "react-i18next";
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


const languageDetector = new LanguageDetector(null, {
  order: ['querystring'],
  lookupQuerystring: 'lang'
});



export default i18n
  .use(languageDetector)
  .use(XHR)
  .use(reactI18nextModule)
  .init({
    preload: [process.env.REACT_APP_DEFAULT_LANG],
    debug: true,
    fallbackLng: process.env.REACT_APP_DEFAULT_LANG,
    react: {
      wait: true,
    }
  });
