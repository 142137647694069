import {
  BEGIN_FETCH_JWT,
  END_SUCCESS_FETCH_JWT,
  END_ERROR_FETCH_JWT,
  DONE_FIRE_DEVICE_EVENT,
  ERROR_FIRE_DEVICE_EVENT,
  BEGIN_CHECK_CONSENTS,
  END_SUCCESS_CHECK_CONSENTS,
  END_ERROR_CHECK_CONSENTS,
  UPDATE_LANG,
  UPDATE_CONFIGURATION,
  REMOVED_JWT_COOKIE_DONE,
  BEGIN_GARMIN_LOGUT
} from './commonservices.actions';

const stateDefault = {
  inStartUp: true,
  loading: null,
  env: null,
  lang: 'fr',
  platform: 'unknown',
  version: 'unknown',
  isAuth: false,
  jwt: null,
  need_consents: null,
  client_id: process.env.REACT_APP_DEFAULT_CLIENT_ID,
  error: null
};

export default (state = stateDefault, action) => {
  switch (action.type) {
    case UPDATE_LANG:
      return {
        ...state,
        lang: action.lang
      };
    case UPDATE_CONFIGURATION:
      return {
        ...state,
        inStartUp: false,
        loading: null,
        env: action.payload.config,
        jwt: action.payload.jwt,
        lang: action.payload.lang,
        platform: action.payload.platform,
        version: action.payload.version,
        client_id: action.payload.client_id,
        error: null
      };
    case DONE_FIRE_DEVICE_EVENT:
      return {
        ...state,
        loading: 'Waiting for device action...',
        error: null
      };
    case ERROR_FIRE_DEVICE_EVENT:
      return {
        ...state,
        loading: null,
        error: action.error
      };
    case BEGIN_FETCH_JWT:
      return {
        ...state,
        loading: 'Generation of public key (JWT)...',
        error: null
      };
    case BEGIN_GARMIN_LOGUT:
      return {
        ...state,
        loading: 'Request Logout to Garmin service...',
        error: null
      };
    case END_SUCCESS_FETCH_JWT:
      return {
        ...state,
        jwt: action.payload.jwt,
        isAuth: true,
        loading: null,
        error: null
      };
    case END_ERROR_FETCH_JWT:
      return {
        ...state,
        jwt: null,
        isAuth: false,
        loading: null,
        error: action.error
      };
    case BEGIN_CHECK_CONSENTS:
      return {
        ...state,
        loading: 'Check your consents...',
        error: null
      };
    case END_SUCCESS_CHECK_CONSENTS:
      return {
        ...state,
        loading: null,
        need_consents: action.payload.need_consents,
        error: null
      };
    case END_ERROR_CHECK_CONSENTS:
      return {
        ...state,
        loading: null,
        need_consents: false,
        error: action.error
      };
    case REMOVED_JWT_COOKIE_DONE:
      return {
        ...state,
        jwt: null,
        isAuth: false,
        error: null
      };
    default:
      return state;
  }
};
