import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  removeCookie
} from "../CommonServices/commonservices.actions";

class NavLogout extends Component {

  render() {
    this.props.removeCookie();
    window.location.replace(this.props.landing);
    return null;
  }

}

const mapStateToProps = state => ({
  ...state.AuthInfo,
  ...state.Common
});

const mapDispatchToProps = dispatch => ({
  removeCookie: () => dispatch(removeCookie())
});


export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavLogout)
);
