import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './OptionsButton.scss';
import icon from './gears.svg';

class OptionsButton extends Component {
  render() {
    let button;
    if (this.props.NODE_ENV !== 'production') {
      button = (
        <Link to={this.props.to}>
          <img src={icon} className="debug-btm pulse" alt="debug" />
        </Link>
      );
    } else {
      button = '';
    }
    return button;
  }
}

const mapStateToProps = state => ({
  ...state.Common.env
});

export default connect(
  mapStateToProps,
  null
)(OptionsButton);
