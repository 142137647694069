import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { dismissAlert } from '../AlertBox/alertbox.actions';

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2
  }
});

class AlertBox extends React.Component {
  handleClose = (event, reason) => {
    this.props.dismissAlert();
  };

  render() {
    const { classes } = this.props;

    if (this.props.message !== undefined && this.props.error !== undefined)
      console.error('Extra error', this.props.error);

    let errorBox = (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={this.props.message !== undefined}
          autoHideDuration={null}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{this.props.message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
    return errorBox;
  }
}

AlertBox.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...state.AlertBox
});

const mapDispatchToProps = dispatch => ({
  dismissAlert: () => dispatch(dismissAlert())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AlertBox));
