import {
  SHOW_ALERT_BOX,
  SHOW_FATAL_BOX,
  DISMISE_ALERT_BOX
} from './alertbox.actions';

const AlertBoxStateDefaults = {};

export default (state = AlertBoxStateDefaults, action) => {
  switch (action.type) {
    case SHOW_FATAL_BOX:
      return {
        message: action.payload.message,
        error: action.payload.error,
        isFatal: true
      };
    case SHOW_ALERT_BOX:
      return {
        message: action.payload.message,
        error: action.payload.error,
        isFatal: false
      };
    case DISMISE_ALERT_BOX:
      return AlertBoxStateDefaults;
    default:
      return state;
  }
};
