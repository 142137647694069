export const SHOW_ALERT_BOX = 'SHOW_ALERT_BOX';
export const SHOW_FATAL_BOX = 'SHOW_FATAL_BOX';

export const DISMISE_ALERT_BOX = 'DISMISE_ALERT_BOX';

export function showAlertBox(message, error) {
  return {
    type: SHOW_ALERT_BOX,
    payload: {
      message,
      error
    }
  };
}
export function showFatalBox(message, error) {
  return {
    type: SHOW_FATAL_BOX,
    payload: {
      message,
      error
    }
  };
}

export function dismissAlert() {
  return {
    type: DISMISE_ALERT_BOX
  };
}
