import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavConsents from '../../components/NavConsents';
import NavAuth from '../../components/NavAuth';
import OptionsButton from '../../components/OptionsButton';
import Loader from '../../components/Loader';
import {
  removeCookie,
  doLogout,
  doRedirect
} from '../../components/CommonServices/commonservices.actions';

import './Auth.scss';

class AuthPage extends Component {

  render() {

    let content;
    if (this.props.loading !== null) {
      content = <Loader message={this.props.loading} />;
    } else if (this.props.isAuth === false) {
      content = (
        <NavAuth action={this.props.action} ticket={this.props.ticket} origin={this.props.origin} />
      );
    } else if (this.props.need_consents === true) {
        content = <NavConsents landing={this.props.landing} />;
    } else if (
        this.props.loading === null &&
        this.props.jwt !== null &&
        this.props.isAuth === true &&
        this.props.need_consents === false) {
          const message = 'Redirect to: ' + this.props.landing;
          content = <Loader message={message} />;
          doRedirect(decodeURIComponent(this.props.origin));
    } 
    else {
      content = '';
    } 

    const page = (
      <div>
        {content}
        <OptionsButton to="/debug" />
      </div>
    );

    return page;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.Common
  };
};

const mapDispatchToProps = dispatch => ({
  removeCookie: () => dispatch(removeCookie()),
  doLogout: landing_url => dispatch(doLogout(landing_url))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthPage);
