import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { fetchJWTProviderError } from "./navauth.actions";
import {
  doRedirect,
  fetchJWT,
  removeCookie
} from "../CommonServices/commonservices.actions";
import Loader from "../Loader";

class NavAuth extends Component {

  componentDidMount() {

    if (this._validateProps()) {
      this.props.fetchJWT(
          process.env.REACT_APP_URL_MARINE_PROFILE,
          this.props.client_id,
          this.props.ticket,
          this._serviceUrl(window.location.origin, this.props.origin, this.props.client_id)
        );
    } else {
      // The component is not initialized correctly (e.g. required serviceTicket is missing)
      this.props.JwtProviderError();
      this.props.removeCookie();
      doRedirect(process.env.REACT_APP_DEFAULT_ERROR_PAGE);
    }
  }

  render() {
    const message = 'Redirect to: ' + this.props.landing;
    return <Loader message={message} />;
  }

  _serviceUrl(route, origin, clientId) {
    let serviceUrl  = new URL(`/${process.env.REACT_APP_SSO_AUTH_ROUTE}`, route);
    serviceUrl.searchParams.set('origin', origin);
    serviceUrl.searchParams.set('clientId', clientId);
    return serviceUrl.href;
  }

  _validateProps() {
    if (!this.props.origin)
      return false;
    return true;
  }
}

const mapStateToProps = state => ({
  ...state.AuthInfo,
  ...state.Common
});

const mapDispatchToProps = dispatch => ({
  JwtProviderError: () => dispatch(fetchJWTProviderError()),
  fetchJWT: (basePath, client_id, serviceToken, serviceUrl) =>
    dispatch(fetchJWT(basePath, client_id, serviceToken, serviceUrl)),
  removeCookie: () => dispatch(removeCookie())
});

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavAuth)
);
