import axios from 'axios';
import { Cookies } from 'react-cookie';
import fetchParam from '../../helpers/fetchParam';
import { showAlertBox } from '../AlertBox/alertbox.actions';

const cookies = new Cookies();

export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const UPDATE_LANG = 'UPDATE_LANG';

export const ERROR_FIRE_DEVICE_EVENT = 'ERROR_ON_FIRE_MESSAGE';
export const DONE_FIRE_DEVICE_EVENT = 'DONE_FIRE_DEVICE_EVENT';

export const SAVED_JWT_COOKIE_DONE = 'SAVED_JWT_COOKIE_DONE';
export const REMOVED_JWT_COOKIE_DONE = 'REMOVED_JWT_COOKIE_DONE';

export const BEGIN_FETCH_JWT = 'BEGIN_FETCH_JWT';
export const END_SUCCESS_FETCH_JWT = 'END_SUCCESS_FETCH_JWT';
export const END_ERROR_FETCH_JWT = 'END_ERROR_FETCH_JWT';

export const BEGIN_CHECK_CONSENTS = 'BEGIN_CHECK_CONSENTS';
export const END_SUCCESS_CHECK_CONSENTS = 'END_SUCCESS_CHECK_CONSENTS';
export const END_ERROR_CHECK_CONSENTS = 'END_ERROR_CHECK_CONSENTS';

export const BEGIN_GARMIN_LOGUT = 'BEGIN_GARMIN_LOGUT';

export function getCookieName(client_id) {
  return process.env.REACT_APP_COOKIE_NAME + '-' + client_id;
}

export function getAllCookieName() {
  return [
    'NAV-DATA-NAVIONICS_ECOMMERCE',
    'NAV-DATA-NAVIONICS_CHART_INSTALLER',
    'NAV-DATA-NAVIONICS_DEV_PORTAL'
  ];
}

export function getClientIdByCookieName(cookieName) {
  return cookieName.replace(/^NAV-DATA-/, '');
}

export function loadCurrentConfiguration() {

  return dispatch => {
    const config = process.env;
    let lang = process.env.REACT_APP_DEFAULT_LANG;
    let deviceInformation = null;
    let jwt = null;
    let client_id = null;

    try {
      client_id = fetchParam(
        window.location.search,
        'clientId',
        process.env.REACT_APP_DEFAULT_CLIENT_ID
      );
    } catch (error) {
      console.error('Error on discovery clientID ', error);
    }

    try {
      deviceInformation = JSON.parse(window.getDeviceInfo());
    } catch (error) {
      console.error('Error On fetch Device Information', error);
      deviceInformation.platform = 'WEB';
      deviceInformation.platform = process.env.REACT_APP_VERSION;
    }
    const platform = deviceInformation.platform;
    const version = deviceInformation.version;

    try {
      lang = fetchParam(
        window.location.search,
        'lang',
        process.env.REACT_APP_DEFAULT_LANG
      );
    } catch (error) {
      console.error('Error on discovery lang ', error);
    }

    return dispatch({
      type: UPDATE_CONFIGURATION,
      payload: {
        config,
        lang,
        jwt,
        platform,
        version,
        client_id
      }
    });
  };
}

export function updateLang(lang) {
  return {
    type: UPDATE_LANG,
    lang
  };
}

export function doLogout(landingPage) {

  return dispatch => {
    dispatch(doLogoutBegin());
    if (landingPage === process.env.REACT_APP_DEFAULT_SERVICE_PAGE)
      landingPage = process.env.REACT_APP_DEFAULT_ERROR_PAGE;
    dispatch(removeCookie(landingPage));
  };
}

export function doLogoutBegin() {
  return {
    type: BEGIN_GARMIN_LOGUT
  };
}

export function doRedirect(landingPage) {
  if (landingPage !== 'NONE') {
      setTimeout(() => {
          window.location = landingPage;
        },100
    );
  }
  return {
    type: 'DO_REDIRECT'
  };
}

export function getCookieByName(cookieName) {
  return cookies.get(cookieName);
}

export function saveCookie(jwt, client_id) {
  cookies.set(getCookieName(client_id), jwt, {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    expires: 0
  });
  return {
    type: SAVED_JWT_COOKIE_DONE
  };
}

export function removeCookie(landingPage) {
  getAllCookieName().forEach((item, index) => {
    cookies.remove(item, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN
    });
  });

  if (landingPage) {
    if (landingPage !== "NONE") {
      window.location = landingPage;
    } else {
      return {
        type: "DO_REDIRECT"
      };
    }
  } else {
    return {
      type: REMOVED_JWT_COOKIE_DONE
    };
  }
}

///-------------- GET JWT
export function fetchJWT(basepath, clientId, serviceToken, serviceUrl) {
  console.info(
    'Request Fetch of JWT. Param IN: ',
    basepath,
    clientId,
    serviceToken,
    serviceUrl
  );
  const su_encoded = encodeURIComponent(serviceUrl);
  console.info('Request Fetch of JWT. Param ON REQ ', su_encoded);
  return dispatch => {
    dispatch(fetchJWTBegin());
    return axios({
      method: 'GET',
      url: '/authentication/access-token',
      baseURL: basepath,
      headers: {
        'X-GARMIN-CLIENT-ID': clientId
      },
      params: {
        serviceTicket: serviceToken,
        serviceUrl: serviceUrl
      }
    })
      .then(response => {
        if (response.status !== 200) {
          return dispatch(
            fetchJWTError('Error on retrive the JWT:  ' + response.statusText, serviceUrl)
          );
        } else {
          return dispatch(
            fetchJWTSuccess(
              clientId,
              response.data
            )
          );
        }
      })
      .catch(error => {
        return dispatch(fetchJWTError(error, serviceUrl));
      });
  };
}

export function fetchJWTBegin() {
  return {
    type: BEGIN_FETCH_JWT
  };
}

export function fetchJWTSuccess(client_id, token) {
  return (dispatch, getState) => {
    const basepath = process.env.REACT_APP_CONSENTS_HOST;
    dispatch(saveCookie(token, client_id));
    dispatch(checkConsents(basepath, client_id, token));
    return dispatch({
      type: END_SUCCESS_FETCH_JWT,
      payload: {
        jwt: token
      }
    });
  };
}

export function fetchJWTError(error, defaultLandingPage) {

  //AVOID DDOS on consents
  // var ms=1000;
  // var start = Date.now();
  // var now = start;
  // while (now - start < ms) {
  //   now = Date.now();
  // }

  return dispatch => {

    let redirectUlr = defaultLandingPage;
    if ( process.env.REACT_APP_VERBOSE === "true" ){
      dispatch(showAlertBox('Fetch JWT Error', error));
    }

    if (redirectUlr) {
      const query = (new URL(redirectUlr)).searchParams;
      redirectUlr = query.get("origin")? query.get("origin") : redirectUlr;
    }

    dispatch( doLogout(redirectUlr) )

    return dispatch({
      type: END_ERROR_FETCH_JWT,
      payload: {
        error
      }
    });
  };
}

////------------- Check Consents

export function checkConsents(basepath, clientId, jwt) {
  return dispatch => {
    dispatch(checkConsentsBegin());
    return axios({
      method: 'get',
      url: '/profile/v1/consents/status',
      baseURL: basepath,
      headers: {
        authorization: 'Bearer '+ jwt,
        'X-NAVIONICS-CLIENT-ID': clientId
      },
      params: {
        filter: 'REQUIRED',
        state: 'MISSING'
      }
    })
      .then(response => {
        if (response.status !== 200) {
          console.error('Error On consents status page');
          dispatch(checkConsentsError('Error On consents status page'));
        }
        return response;
      })
      .then(res => {
        let userConsents = res.data.consents;
        const isRequired = !(
          Object.keys(userConsents).length === 0 &&
          userConsents.constructor === Object
        );
        return dispatch(checkConsentsSuccess(isRequired));
      })
      .catch(error => {
        console.log(error);
        return dispatch(checkConsentsError(error));
      });
  };
}

export function checkConsentsBegin() {
  return {
    type: BEGIN_CHECK_CONSENTS
  };
}

export function checkConsentsSuccess(need_consents) {
  return (dispatch, getState) => {
    return dispatch({
      type: END_SUCCESS_CHECK_CONSENTS,
      payload: {
        need_consents
      }
    });
  };
}

export function checkConsentsError(error) {
  return dispatch => {
    dispatch(showAlertBox('Consents Error', error));
    return {
      type: END_ERROR_CHECK_CONSENTS,
      payload: {
        error
      }
    };
  };
}
