import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PAGE_CLOSE_REQUEST, PAGE_ERROR } from '../../helpers/constants';
import { updateDeviceInfo } from './actions';
import './DebugButtonBar.scss';

const DUMMY_LOGGED_IN = {
  source: 'sso',
  attributes: {
    event: 'AUTHENTICATED',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpY29uX3VybCI6Imh0dHBzOi8vYXNzZXRzLmNodWNrbm9ycmlzLmhvc3QvaW1nL2F2YXRhci9jaHVjay1ub3JyaXMucG5nIiwiaWQiOiJYZjJwazQ5QVJSMkYzWVBxYUpYOGpnIiwidXJsIjoiaHR0cHM6Ly9hcGkuY2h1Y2tub3JyaXMuaW8vam9rZXMvWGYycGs0OUFSUjJGM1lQcWFKWDhqZyIsInZhbHVlIjoiV2hlbiBhIHRlbGVtYXJrZXRlciBwaG9uZXMgQ2h1Y2sgTm9ycmlzJyBudW1iZXIsIHRoZSBmaXJzdCB0aGluZyB0aGV5IGhlYXIgaXMgdGhlIHBsZWFkaW5nIHZvaWNlIG9mIG9uZSB0aGVpciBsb3ZlZCBvbmVzLiJ9.T08HG70GODe2spcnGu1-D3OGrm6mrKYVG0WbuXuXWNw'
  }
};

class DebugButtonBar extends Component {
  render() {
    return (
      <div className="inline">
        <button
          className="jnc-simple-btn jnc-simple-btn-light-blue jnc-cas-btn"
          onClick={() => {
            window.hook_event(DUMMY_LOGGED_IN);
          }}
        >
          Fake JWT
        </button>
        <button
          className="jnc-simple-btn jnc-simple-btn-light-blue jnc-cas-btn"
          onClick={() => {
            window.hook_event(PAGE_CLOSE_REQUEST);
          }}
        >
          Request Close
        </button>
        <button
          className="jnc-simple-btn jnc-simple-btn-light-blue jnc-cas-btn"
          onClick={() => {
            window.hook_event(PAGE_ERROR);
          }}
        >
          Raise Error
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.DeviceInfo
});

const mapDispatchToProps = dispatch => ({
  updateDeviceInfo: (p, v) => dispatch(updateDeviceInfo(p, v))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugButtonBar);
