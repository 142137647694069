(function(global) {
  if (typeof global.getDeviceInfo !== 'function') {
    /**
     * Return the current Version of loader
     * @returns {string} the version in string format
     */
    global.getDeviceInfo = function() {
      const out = {
        platform: global.navigator.userAgent,
        version: global.navigator.appVersion
      };
      return JSON.stringify(out);
    };
  }

  if (typeof global.hook_event !== 'function') {
    /**
     * Function used to hook the action that are occursed on the page
     * @param {string} action JSON rapresentation of action
     * @returns {bool} Response of action
     */
    global.hook_event = function(action) {
      console.log(action);
      return;
    };
  }
})(typeof window === 'undefined' ? this : window);
