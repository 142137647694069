import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import {
  loadCurrentConfiguration,
  removeCookie
} from "./commonservices.actions";
import Loader from "../Loader";
import AlertBox from "../AlertBox";

class CommonServices extends Component {
  componentDidMount() {
    this.props.loadCurrentConfiguration();
  }

  render() {
    let content = "";
    let header = "";

    if (this.props.Common.inStartUp !== true) {
      content = this.props.children;
    } else {
      content = <Loader message="Loading Configuration..." />;
    }

    return (
      <div>
        {header}
        <div className="page-content container">
          <div className="container_movecenter">
            <div className="card">
              <div className="login-form">
                <div className="jnc-cas">
                  <AlertBox />
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  loadCurrentConfiguration: () => dispatch(loadCurrentConfiguration()),
  removeCookie: () => dispatch(removeCookie())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonServices);
