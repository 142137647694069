import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import fetchParam from './helpers/fetchParam';
import DebugPage from './screens/debug';
import AuthPage from './screens/auth';
import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import CommonServices from './components/CommonServices';
import SSOModule from './components/SSOModule';
import NavLogout from './components/Logout';
import { I18nextProvider } from 'react-i18next';
import i18n from './helpers/i18n.js';
import './index.css';
dotenv.config();

ReactDOM.render(
  <Provider store={configureStore()}>
    <I18nextProvider i18n={i18n}>
      <Router>
        <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <SSOModule
                  {...props}
                  action="login"
                  landing={fetchParam(
                    props.location.search,
                    'service',
                    process.env.REACT_APP_DEFAULT_SERVICE_PAGE
                  )}
                  clientId={fetchParam(
                    props.location.search,
                    'clientId',
                    process.env.REACT_APP_DEFAULT_CLIENT_ID
                  )}
                />
              )}
            />
            <Route
              exact
              path="/login"
              render={props => (
                <SSOModule
                  {...props}
                  action="login"
                  landing={fetchParam(
                    props.location.search,
                    'service',
                    process.env.REACT_APP_DEFAULT_SERVICE_PAGE
                  )}
                  clientId={fetchParam(
                    props.location.search,
                    'clientId',
                    process.env.REACT_APP_DEFAULT_CLIENT_ID
                  )}
                />
              )}
            />
            <Route
              exact
              path="/cas/login"
              render={props => (
                <SSOModule
                  {...props}
                  action="login"
                  landing={fetchParam(
                    props.location.search,
                    'service',
                    process.env.REACT_APP_DEFAULT_SERVICE_PAGE
                  )}
                  clientId={fetchParam(
                    props.location.search,
                    'clientId',
                    process.env.REACT_APP_DEFAULT_CLIENT_ID
                  )}
                />
              )}
            />

            <Route
              exact
              path="/logout"
              render={props => (
                <SSOModule
                  {...props}
                  action="logout"
                  landing={fetchParam(
                    props.location.search,
                    'service',
                    process.env.REACT_APP_DEFAULT_SERVICE_PAGE
                  )}
                  clientId={fetchParam(
                    props.location.search,
                    'clientId',
                    null
                  )}
                />
              )}
            />

            <Route
              exact
              path="/cas/logout"
              render={props => (
                <SSOModule
                  {...props}
                  action="logout"
                  landing={fetchParam(
                    props.location.search,
                    'service',
                    process.env.REACT_APP_DEFAULT_SERVICE_PAGE
                  )}
                  clientId={fetchParam(
                    props.location.search,
                    'clientId',
                    null
                  )}
                />
              )}
            /> 

            <Route
              exact
              path="/navlogout"
              render={props => (
                <NavLogout
                  {...props}
                  action="logout"
                  landing={fetchParam(
                    props.location.search,
                    'origin',
                    process.env.REACT_APP_DEFAULT_LOGOUT_SERVICE_PAGE
                  )}
                />
              )}
            /> 

        <Route path="/">
          <CommonServices>
            <Switch>
              <Route
                exact
                path="/navauth"
                render={props => (
                  <AuthPage
                    {...props}
                    action="login"
                    ticket={fetchParam(
                      props.location.search,
                      'ticket',
                      null
                    )}
                    origin={fetchParam(
                      props.location.search,
                      'origin',
                      null
                    )}
                    clientId={fetchParam(
                      props.location.search,
                      'clientId',
                      null
                    )}
                  />
                )}
              />

              <Route path='/debug' component={props => {
                if (process.env.NODE_ENV !== 'production') {
                  return <DebugPage {...props} />
                }

              }}/>

            </Switch>
          </CommonServices>
        </Route>
        </Switch>
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
