import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getCookieByName,
  getAllCookieName,
  getClientIdByCookieName
} from '../CommonServices/commonservices.actions';

class SSOModule extends Component {

  constructor(props) {
    super(props);
    this.authOriginService = window.location.origin
    this.ssoUrl = this._getSSOserviceUrl(this.props.action);
  }

  render() {

    const clientId = this._retrieveClientId();
    const ssoUrl = this._generateSSOurl(this.ssoUrl, this.props.landing, clientId);
    window.location.replace(ssoUrl);
    return null;
  }

  _generateSSOurl(ssoService, origin, clientId) {

    const accessRoute = this._getRedirectAccessRoute(this.props.action);
    let service  = new URL(`/${accessRoute}`, this.authOriginService);
    service.searchParams.set('origin', origin);
    service.searchParams.set('clientId', clientId);

    const ssoUrl  = new URL(ssoService);
    ssoUrl.searchParams.set('clientId', clientId);
    ssoUrl.searchParams.set('service', service);

    return ssoUrl;
  }

  _getSSOserviceUrl(action) {

    let serviceName;
    switch(action) {
      case 'logout': {
        serviceName = process.env.REACT_APP_SSO_LOGOUT_PATH;
        break;
      }
      default: {
        serviceName = process.env.REACT_APP_SSO_SIGNIN_PATH;
        break;
      }
    }
    return `${process.env.REACT_APP_SSO_SERVICE}/${this.props.lng}/${serviceName}`;
  }

  _getRedirectAccessRoute(action) {

    switch(action) {
      case 'logout': {
        return process.env.REACT_APP_SSO_LOGOUT_ROUTE;
      }
      default: {
        return process.env.REACT_APP_SSO_AUTH_ROUTE;
      }
    }
  }

  _retrieveClientId() {

      let clientId = this.props.clientId;
      if (!clientId) {
        for (let cookie of getAllCookieName()) {
          if (getCookieByName(cookie)) {
            return getClientIdByCookieName(cookie);
          }
        };
      }
      return !clientId ? process.env.REACT_APP_DEFAULT_CLIENT_ID : clientId;
  }

}

export default withNamespaces()(
  connect(
  )(SSOModule)
);
