export const PAGE_READY = {
  source: 'page',
  attributes: {
    event: 'ready'
  }
};
export const PAGE_ERROR = {
  source: 'page',
  attributes: {
    event: 'error'
  }
};
export const PAGE_CLOSE_REQUEST = {
  source: 'page',
  attributes: {
    event: 'requestClose'
  }
};
