import React from 'react';

import './styles.scss';

export default class Loader extends React.Component {
  render() {
    let content = '<span></span>';
    if (this.props.message || this.props.active === true) {
      content = (
        <div>
          <div className="loader">{this.props.children || 'Loading...'}</div>
          { process.env.REACT_APP_VERBOSE === "true" && this.props.message ? (
            <div className="loader_label">{this.props.message}</div>
          ) : null}
        </div>
      );
    }

    return content;
  }
}
