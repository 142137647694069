import qs from 'querystringify';

var getLocation = function(href) {
    var l = document.createElement("a");
    l.href = href;
    return l;
};

export default function(queryString, key, defaults) {

  	const query_params = qs.parse(queryString);

  	var out = query_params[key] || defaults;

  	if (key === 'service' && getLocation(out).hostname.indexOf(process.env.REACT_APP_COOKIE_DOMAIN) === -1) {
		out = defaults;
	}
	return out;

}
