import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkConsentsSuccess } from '../CommonServices/commonservices.actions';
import './index.scss';

class NavConsents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iFrameHeight: '900px'
    };
    this.onMessageFromFrame = this.onMessageFromFrame.bind(this);
    this.resizeIframe = this.resizeIframe.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessageFromFrame, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessageFromFrame, false);
  }

  render() {
    const host = process.env.REACT_APP_CONSENTS_HOST;
    const lang = this.props.lang;
    const client_id = this.props.client_id;
    const auth_cookie_name = process.env.REACT_APP_COOKIE_NAME + '-' + client_id;
    const url =
      host +
      '/profile/v1/consents/status_page_s?filter=REQUIRED&state=MISSING&lang=' +
      lang +
      '&auth_cookie_name=' +
      auth_cookie_name +
      '&client_id=' +
      client_id;
    return (
      <iframe
        style={{
          width: '100%',
          height: this.state.iFrameHeight,
          overflow: 'visible'
        }}
        src={url}
        title="consents_frame"
        width="100%"
        height={this.state.iFrameHeight}
        scrolling="no"
        frameBorder="0"
        className="consentsFrame"
      />
    );
  }

  resizeIframe(obj) {
    //console.log(obj.contentWindow.document.body.scrollHeight + 'px')
    obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
  }

  onMessageFromFrame(event) {
    if (event.origin !== process.env.REACT_APP_CONSENTS_HOST) {
      return;
    } else {
      this.props.disableConsenst();
    }
  }
}

const mapStateToProps = state => ({
  ...state.Common
});

const mapDispatchToProps = dispatch => ({
  disableConsenst: () => dispatch(checkConsentsSuccess(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavConsents);
