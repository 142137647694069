import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DebugButtonBar from '../../components/DebugButtonBar';
import LogoNavionics from '../../components/LogoNavionics';
import './debug.scss';

class Debug extends Component {
  render() {
    return (
      <div className="wrapper">
        <Link to="/">
          {' '}
          <h1>
            <LogoNavionics /> Return
          </h1>{' '}
        </Link>
        <h2>Debug Bottons</h2>
        <DebugButtonBar />
        <h2>Configuration</h2>
        <ReactJson src={this.props.env} />
        <h2>Full properties</h2>
        <ReactJson src={this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.Common
});

export default connect(
  mapStateToProps,
  null
)(Debug);
